import { FC, useEffect, useState } from 'react';
import { DocumentListItem } from '../../../models/Document';
import { ApiResponse } from '../../../models/ApiResponse';
import Checkbox from '../../shared/form-control/Checkbox';
import FormUtils from '../../../utils/FormUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import DocumentSpaceIcon from '../../shared/icon/DocumentSpaceIcon';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { useTranslation } from 'react-i18next';
import SortableHeading from '../../shared/data-grid/SortableHeading';
import Loader from '../../shared/Loader';
import Tooltip from '../../shared/Tooltip';
import { useCurrentClient } from '../../../global-state/Clients';

export type SelectableDocumentListItem = DocumentListItem & {
  selected: boolean;
  disabled: boolean;
};

type ReferenceDocumentsListProps = {
  documentsPaged: ApiResponse<SelectableDocumentListItem[]>;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading?: boolean;
  onDocumentSelect: (id: string, value: boolean) => void;
};

const ReferenceDocumentsList: FC<ReferenceDocumentsListProps> = (props) => {
  const { documentsPaged, isLoading = false, onSort, sortBy, onLoadMore, onDocumentSelect } = props;
  const currentClient = useCurrentClient((x) => x.value);
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});
  const currentPage = documentsPaged.pageNumber || 0;
  const totalPages = documentsPaged.totalPages || 0;
  const { t } = useTranslation(['activity-type', 'documents', 'common']);

  const updateSortExpression = (field: string, expression: string) => {
    const match = expression.match(/^([-+])/);
    let ascOrDesc = '-';
    if (match) {
      ascOrDesc = match[1];
    }
    const updatedSortExpressions: Record<string, string> = {};

    Object.keys(sortExpressions).forEach((key) => {
      updatedSortExpressions[key] = ascOrDesc + key;
    });

    setSortExpressions(updatedSortExpressions);

    const fullExpression = Object.values(updatedSortExpressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  return (
    <div>
      <SortableHeading title={t('documents:list.name')} onSort={updateSortExpression} expression={sortExpressions['prefix']} />
      <div className="mt-2">
        {documentsPaged?.data.map((document, i) => {
          const isLast = documentsPaged.data.length === i + 1;
          const templateName = (
            <>
              {FormUtils.getDocumentTitle(document)}
              {FormUtils.formHostName(document, currentClient?.id || '', ' ({client})')}
            </>
          );
          const description = LanguageUtils.getTranslation('description', document.translations || {});
          const tootltip = (
            <>
              <span>
                {templateName} - {`v${document.majorVersion}`}
              </span>
              <div>{description}</div>
            </>
          );

          return (
            <div
              key={document.id}
              className={`flex items-start gap-4 pb-4 ${document.disabled ? 'opacity-75' : ''}`}
              ref={isLast ? lastElementRef : undefined}
            >
              <div className="-my-1">
                <Checkbox
                  value={document.selected}
                  disabled={document.disabled}
                  onChange={(value: boolean) => onDocumentSelect(document.id, value)}
                />
              </div>
              <div className="truncate">
                <div>
                  <Tooltip text={tootltip} truncatedTextMode>
                    {(tooltip) => (
                      <div {...tooltip} className="truncate">
                        <span className="font-medium">{templateName}</span>
                        <span className="text-gray-3 text-dpm-14 ml-2">{`v${document.majorVersion}`}</span>
                        <span className="text-accent-1 text-dpm-12 ml-2">
                          {document.isSystemTemplateForm
                            ? LanguageUtils.getTranslation('name', document.templateModuleTranslations)
                            : LanguageUtils.getTranslation('title', document.translations)}
                        </span>
                      </div>
                    )}
                  </Tooltip>
                </div>
                <div className="text-dpm-14 text-gray-3">
                  <Tooltip text={tootltip} truncatedTextMode>
                    {(tooltip) => (
                      <div {...tooltip} className="truncate">
                        {description}
                      </div>
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        })}
        {isLoading && (
          <div className="flex flex-col items-center py-6">
            <Loader size={16} centered={false} />
          </div>
        )}
        {documentsPaged?.data.length === 0 && !isLoading && (
          <div className="flex w-full justify-center text-center">
            <div className="flex h-[50vh] w-4/5 flex-col items-center justify-center">
              <DocumentSpaceIcon className="bg-background-1 mt-5 h-16 w-16 rounded-full p-4" />
              <Heading size={HeadingSize.H4} className="mt-4">
                {t(`activity-type:document-reference-modal.empty.title`)}
              </Heading>
              <p className="mb-8">{t(`activity-type:document-reference-modal.empty.subTitle`)}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceDocumentsList;
