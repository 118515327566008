import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import ManageAccess from '../ManageAccess';
import { useCreateDocumentWizard } from '../../../contexts/CreateDocumentContext';
import { Access } from '../../../models/Access';
import DocumentService from '../../../services/DocumentService';
import { ClientFormUserRole, ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';
import useInviteUser from '../../../hooks/useInviteUser';
import { useCurrentUser } from '../../../global-state/Auth';

const AccessStep = () => {
  const { nextStep, prevStep, stepNames, newDocument, setNewDocument, onCreated, clientUsers, reset, classes, onUserInvited } =
    useCreateDocumentWizard();
  const { t } = useTranslation(['documents', 'common']);
  const { inviteUserMode, setInviteUserMode, inviteUser, setInvitedUser, invitedUser, setInviteUserDisable, inviteUserDisable } = useInviteUser();
  const [isSaving, setIsSaving] = useState(false);
  const currentUser = useCurrentUser((x) => x.value);
  const internalOnClose = useCallback(() => {
    setInviteUserMode(false);
  }, [setInviteUserMode]);

  const onSaveUserInvite = useCallback(() => {
    setIsSaving(true);
    inviteUser()?.then((res) => {
      setNewDocument &&
        setNewDocument((prev) => ({
          ...prev,
          accessConfiguration: {
            ...prev.accessConfiguration,
            users: [
              ...prev.accessConfiguration.users,
              { userId: res.data.userId, role: invitedUser?.defaultClientFormRole || ClientFormUserRole.Viewer },
            ],
          },
        }));

      onUserInvited();
      setInviteUserMode(false);
      setIsSaving(false);
    });
  }, [inviteUser, invitedUser?.defaultClientFormRole, onUserInvited, setInviteUserMode, setNewDocument]);

  const createDocument = useCallback(() => {
    setIsSaving(true);
    DocumentService.create(newDocument)
      .then((res) => {
        reset();
        onCreated(res.data.id, newDocument.clientModuleId || '', newDocument.number + 1);
      })
      .finally(() => setIsSaving(false));
  }, [newDocument, onCreated, reset]);

  const selectedModuleTemplate = useMemo(() => {
    return classes.find((x) => x.clientModuleId === newDocument.clientModuleId)?.templateModule;
  }, [classes, newDocument.clientModuleId]);

  const isValid = useMemo(() => {
    const requiredRoles: ClientFormUserRoleValues[] = [ClientFormUserRole.Owner];
    if (selectedModuleTemplate?.requiresValidation) {
      requiredRoles.push(ClientFormUserRole.Validator);
    }
    if (selectedModuleTemplate?.requiresApproval) {
      requiredRoles.push(ClientFormUserRole.Approver);
    }
    const config = newDocument.accessConfiguration || { accessType: Access.restricted, users: [] };

    for (const role of requiredRoles) {
      const isRoleAssigned = config.users.some((x) => x.role === role);
      if (!isRoleAssigned) {
        return false;
      }
    }

    return true;
  }, [newDocument.accessConfiguration, selectedModuleTemplate?.requiresApproval, selectedModuleTemplate?.requiresValidation]);

  const requiresOwnerAcknowledgement = useMemo(
    () => selectedModuleTemplate?.requiresOwnerAcknowledgement,
    [selectedModuleTemplate?.requiresOwnerAcknowledgement],
  );

  const hasSetDefaultOwner = useRef(false);
  useEffect(() => {
    // Ensure a document always has an owner
    if (!setNewDocument || !currentUser?.id) return;

    if (hasSetDefaultOwner.current) return;
    hasSetDefaultOwner.current = true;

    setNewDocument((prev) => {
      const usersConfig = prev.accessConfiguration.users;
      const users = usersConfig.map((x) => x.userId);
      const userRoles = usersConfig.map((x) => x.role);
      const hasOwner = userRoles.includes(ClientFormUserRole.Owner);
      const hasCurrentUser = users.includes(currentUser.id);

      // Return early if an owner is already set and the currentUser is assigned to the users list
      if (hasOwner && hasCurrentUser) return prev;

      // If there's no owner, the current user is assigned the role Owner.
      // If there is an owner, but it isn't the current user, the current user is assigned the role Contributor.
      const updatedUsers = [
        ...usersConfig,
        {
          userId: currentUser.id,
          role: !hasOwner ? ClientFormUserRole.Owner : ClientFormUserRole.Contributor,
          requiresAction: requiresOwnerAcknowledgement,
        },
      ];

      return {
        ...prev,
        accessConfiguration: {
          ...prev.accessConfiguration,
          users: updatedUsers,
        },
      };
    });
  }, [currentUser?.id, requiresOwnerAcknowledgement, setNewDocument]);

  return (
    <StandardModal
      title={t('create-doc-wizard.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={!inviteUserMode ? prevStep : internalOnClose}
      confirmButtonTitle={!inviteUserMode ? t('create-doc-wizard.buttons.create') : t('common:permissions-modal.manage-add')}
      onConfirmClick={() => (!inviteUserMode ? createDocument() : onSaveUserInvite())}
      tertiaryButtonTitle={t('common:add-or-invite-modal.switch.invite')}
      onTertiaryButtonClick={!inviteUserMode ? () => setInviteUserMode(true) : undefined}
      confirmDisabled={!inviteUserMode ? !isValid : inviteUserDisable}
      confirmLoading={isSaving}
    >
      {stepNames && nextStep && (
        <WizardStepsIndicator activeStepIndex={1} stepNames={stepNames.filter((x) => x === 'detailStep' || x === 'access')} onStepChange={nextStep} />
      )}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        <div className="text-dpm-16 font-medium">{t('manage-wizard.access')}</div>
        <ManageAccess
          defaults={newDocument.accessConfiguration}
          clientUsers={clientUsers}
          onDefaultsChange={(value) => setNewDocument && setNewDocument((prev) => ({ ...prev, accessConfiguration: value }))}
          requireValidator={selectedModuleTemplate?.requiresValidation}
          requireApprover={selectedModuleTemplate?.requiresApproval}
          requireOwner
          inviteUserMode={inviteUserMode}
          onUserInviteValid={(value) => setInviteUserDisable(!value)}
          onUserInviteChange={setInvitedUser}
          modalIsValid={isValid}
        />
      </div>
    </StandardModal>
  );
};

export default AccessStep;
