import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ClientModuleTemplateDefault } from '../../../models/ClientModuleDefaults';
import { Access } from '../../../models/Access';
import User from '../../../models/User';
import UploadStep from './UploadStep';
import DetailStep from './DetailStep';
import AccessStep from './AccessStep';
import PickTemplateStep from './PickTemplateStep';
import { CreateDocumentContext } from '../../../contexts/CreateDocumentContext';
import { DocumentRequest } from '../../../models/Document';
import ReferenceStep from './ReferenceStep';
import { DocumentReferenceAnswer } from '../../form/action-types/document-reference/DocumentReferenceAction';
import { ModalContext } from '../../../contexts/ModalContext';
import { useCurrentClient } from '../../../global-state/Clients';

export const createDocumentWizardSteps = ['reference', 'upload', 'detailStep', 'access', 'fromTemplate'] as const;

export type CreateDocumentWizardStep = (typeof createDocumentWizardSteps)[number];

type Props = {
  classes: ClientModuleTemplateDefault[];
  classDefaults: ClientModuleTemplateDefault | undefined;
  onCancel: () => void;
  activeStep: CreateDocumentWizardStep;
  clientUsers: User[];
  onCreated: (id: string, classId: string, nextDocuNumber: number) => void;
  selectedDocuments?: DocumentReferenceAnswer[];
  disabledDocments?: DocumentReferenceAnswer[];
  onReference?: (references: DocumentReferenceAnswer[]) => void;
  onUserInvited: () => void;
};

const CreateDocumentWizard: FC<Props> = (props) => {
  const { onCancel, activeStep, clientUsers, onCreated, classDefaults, classes, selectedDocuments, disabledDocments, onReference, onUserInvited } =
    props;
  const currentClient = useCurrentClient((x) => x.value);

  const steps = useMemo<Record<CreateDocumentWizardStep, JSX.Element>>(() => {
    return {
      reference: <ReferenceStep />,
      upload: <UploadStep />,
      detailStep: <DetailStep />,
      access: <AccessStep />,
      fromTemplate: <PickTemplateStep />,
    };
  }, []);

  const emptyNewDocument = useMemo(() => {
    return {
      clientModuleId: classDefaults?.clientModuleId,
      majorVersion: 1,
      minorVersion: 0,
      description: '',
      number: classDefaults?.nextDocumentNumber || 1,
      clientId: currentClient?.id || '',
      accessConfiguration: classDefaults?.defaults?.configuration || { accessType: Access.restricted, users: [] },
      subtitle: '',
      predefinedTitle: undefined,
    };
  }, [classDefaults?.clientModuleId, classDefaults?.defaults?.configuration, classDefaults?.nextDocumentNumber, currentClient?.id]);

  const [newDocument, setNewDocument] = useState<DocumentRequest>(emptyNewDocument);

  const [stepStack, setStepStack] = useState([steps[activeStep]]);
  const nextStep = useCallback(
    (name: CreateDocumentWizardStep) => {
      setStepStack((prev) => [...prev, steps[name]]);
    },
    [steps],
  );

  useEffect(() => {
    setStepStack([steps[activeStep]]);
  }, [activeStep, nextStep, steps]);

  const reset = useCallback(() => {
    setNewDocument(emptyNewDocument);
    setStepStack([steps[activeStep]]);
  }, [activeStep, emptyNewDocument, steps]);

  const prevStep = useCallback(() => {
    setStepStack((prev) => prev.filter((_, i) => i !== prev.length - 1));
  }, []);

  const contextValues = useMemo(
    () => ({
      classes,
      newDocument,
      setNewDocument,
      nextStep,
      prevStep,
      cancel: onCancel,
      stepNames: createDocumentWizardSteps,
      clientUsers,
      onCreated,
      reset,
      selectedDocuments,
      disabledDocments,
      onReference,
      onUserInvited,
    }),
    [
      classes,
      clientUsers,
      disabledDocments,
      newDocument,
      nextStep,
      onCancel,
      onCreated,
      onReference,
      onUserInvited,
      prevStep,
      reset,
      selectedDocuments,
    ],
  );

  return (
    <ModalContext.Consumer>
      {(modalContext) => (
        <ModalContext.Provider
          value={{
            ...modalContext,
            onClose: () => {
              reset();
              modalContext.onClose && modalContext.onClose();
            },
          }}
        >
          <CreateDocumentContext.Provider value={contextValues}>{stepStack[stepStack.length - 1]}</CreateDocumentContext.Provider>
        </ModalContext.Provider>
      )}
    </ModalContext.Consumer>
  );
};

export default CreateDocumentWizard;
